import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItemCard from '../cards/menu _card';
import Modal from '@mui/material/Modal';
import '../lists/menu-list.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../../api/axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function MenuItemList() {
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const [searchTerm, setSearchTerm] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [newItemCGST, setNewItemCGST] = useState('');
  const [newItemSGST, setNewItemSGST] = useState('');
  const [newItemCode, setNewItemCode] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [editingItemId, setEditingItemId] = useState(null);
  const [isOpenItem, setIsOpenItem] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    fetchData();
  }, [business_entity_id]);

  const fetchData = async () => {
    try {
      const responseCategories = await axios.get(`businessEntities/getAllMenuCategories/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setCategories(responseCategories.data.allCategories);

      const responseMenuItems = await axios.get(`businessEntities/getAllMenuItems/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setMenuItems(responseMenuItems.data.allMenus);

      const responseSections = await axios.get(`businessEntities/getAllSections/${business_entity_id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      setSections(responseSections.data.allSections);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleAddItem = () => {
    setNewItemName('');
    setNewItemPrice('');
    setNewItemCGST('');
    setNewItemSGST('');
    setNewItemCode('');
    setSelectedCategory(null);
    setEditingItemId(null);
    setOpenModal(true);
  };

  const handleEditItem = (item) => {
    setNewItemName(item.menu_name);
    setNewItemPrice(item.price);
    setNewItemCGST(item.cgst);
    setNewItemSGST(item.sgst);
    setNewItemCode(item.code);
    setSelectedCategory(item.category_id);
    setEditingItemId(item.menu_id);
    setIsOpenItem(item.is_open_item === 0 ? false : true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleItemSubmit = async () => {
    if (!newItemName || !newItemPrice || !newItemCGST || !newItemSGST || !selectedCategory) {
      setSnackbarMessage("All fields are required.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }


    const priceIsValid = !isNaN(newItemPrice) && Number(newItemPrice) >= 0;
    const cgstIsValid = !isNaN(newItemCGST) && Number(newItemCGST) >= 0;
    const sgstIsValid = !isNaN(newItemSGST) && Number(newItemSGST) >= 0;

    if (!priceIsValid || !cgstIsValid || !sgstIsValid) {
      setSnackbarMessage("Price, CGST, and SGST must be valid numbers.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const url = editingItemId
        ? 'businessEntities/updateMenuItems'
        : 'businessEntities/addMenuItem';

      const payload = {
        menuName: newItemName,
        cgst: newItemCGST,
        sgst: newItemSGST,
        code: newItemCode,
        is_open_item: isOpenItem ? 1 : 0,
        expires_on: isOpenItem 
        ? startDate.toISOString().replace('T', ' ').slice(0, 19)
        : null,
        section: selectedSection !== null ? parseInt(selectedSection, 10) : null,
        price: newItemPrice,
        menu_category_id: selectedCategory,
        businessEntityId: sessionStorage.getItem("configBizEntityId"),
      };
      
      


      if (editingItemId) {
        payload.menuId = editingItemId;
      }

      const response = await axios.post(url, payload, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });

      if (response.status === 200) {
        const successMessage = editingItemId ? "Menu item updated successfully!" : "Menu item added successfully!";
        setSnackbarMessage(successMessage);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setOpenModal(false);
        fetchData();
      }
      else if (response.status === 230) {
        const errorMessage = "The item code is already used by another menu item !";
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };


  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      setSnackbarMessage("Please upload a valid CSV file.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const maxFileSize = 5 * 1024 * 1024; //5 mb
    if (file.size > maxFileSize) {
      setSnackbarMessage("File size exceeds the maximum limit of 5MB.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurant_id', sessionStorage.getItem("configBizEntityId"));

    setIsUploading(true);

    try {
      const response = await axios.post(
        '/businessEntities/upload-menu-csv',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: false,
        }
      );

      const successMessage = response.data.message || "Menu CSV uploaded successfully!";
      fetchData();

      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

    } catch (error) {
      let errorMessage = 'Something went wrong with the menu CSV upload!';

      if (error.response) {
        errorMessage = error.response.data.error || `Error: ${error.response.statusText}`;
      } else if (error.request) {
        errorMessage = 'No response from the server. Please try again later.';
      } else {
        errorMessage = `Request error: ${error.message}`;
      }

      console.error('Error uploading CSV:', error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDelete = async (menu_id) => {
    try {
      const response = await axios.delete(`businessEntities/deleteMenuItem/${menu_id}`);

      if (response.status === 200) {
        fetchData();
        setSnackbarMessage("Item deleted successfully");
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting menu item:", error);
      setSnackbarMessage("Failed to delete menu item");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const filteredItems = menuItems.filter(item =>
    item.menu_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <p style={{ textDecoration: 'none', color: 'red' }}>
            Please ensure that the category name is spelled correctly when using the template. This will ensure proper linking; otherwise, a new category will be created.
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a href="/assets/templates/menu.csv" download="menu.csv" style={{ textDecoration: 'none' }}>
            Download CSV Template ?
          </a>
        </div>
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search items"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddItem}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Item
          </Button>

          <input
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <Button
            variant="contained"
            onClick={() => fileInputRef.current.click()}
            style={{ backgroundColor: '#2196F3', color: 'white', marginRight: '10px' }}
            disabled={isUploading}
          >
            {isUploading ? <CloudUploadIcon /> : "Upload CSV"}
          </Button>
        </div>
      </div>
      <div className="menu-container">
        <Grid container spacing={2} justifyContent="center">
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.menu_id}>
                <MenuItemCard item={item} onEdit={() => handleEditItem(item)} onDelete={() => handleDelete(item.menu_id)} />
              </Grid>
            ))
          ) : (
            <div className="empty-list">
              <EmptyIcon style={{ fontSize: 100 }} />
              <p>No items found</p>
            </div>
          )}
        </Grid>
      </div>

      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-container">
          <h4>{editingItemId ? "Edit Menu Item" : "Add Menu Item"}</h4>
          <form>
            <div className="form-group">
              <label>
                Name:
                <input
                  type="text"
                  value={newItemName}
                  onChange={(e) => setNewItemName(e.target.value)}
                  required
                  className="form-input"
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Price:
                <input
                  type="number"
                  value={newItemPrice}
                  onChange={(e) => setNewItemPrice(e.target.value)}
                  required
                  className="form-input"
                />
              </label>
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>

              <div className="form-group" style={{ flex: 1 }}>
                <label>
                  SGST:
                  <input
                    type="number"
                    value={newItemSGST}
                    onChange={(e) => setNewItemSGST(e.target.value)}
                    required
                    className="form-input"
                  />
                </label>
              </div>
              <div className="form-group" style={{ flex: 1 }}>
                <label>
                  CGST:
                  <input
                    type="number"
                    value={newItemCGST}
                    onChange={(e) => setNewItemCGST(e.target.value)}
                    required
                    className="form-input"
                  />
                </label>
              </div>
            </div>


            <div className="form-group">
              <label>
                Category:
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  required
                  className="form-select"
                >
                  <option value="" disabled>Select a category</option>
                  {categories.map((category) => (
                    <option key={category.category_id} value={category.category_id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>

              </label>
            </div>
            <div className="form-group">
              <label>
                Item Code(Optional):
                <input
                  type="text"
                  value={newItemCode}
                  onChange={(e) => (e.target.value.length > 0) ? setNewItemCode(e.target.value) : null}
                  required
                  className="form-input"
                />
              </label>
            </div>
            <div className="formInput">
              <label>Section(Optional):</label>
              <select className="dropdown" value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)}>
                <option value={null}>Select a section</option>
                {sections.map(e => (
                  <option key={e.section_id} value={e.section_id}>
                    {e.section_name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <input
                type="checkbox"
                id="openItemCheckbox"
                checked={isOpenItem}
                onChange={(e) => setIsOpenItem(e.target.checked)}
                style={{ cursor: 'pointer' }}
              />
              <label htmlFor="openItemCheckbox" style={{ cursor: 'pointer' }}>
                Open Item
              </label>
            </div>

            {isOpenItem && (
              <div style={{ marginTop: '15px', padding: '10px', border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '600', color: '#333' }}>
                  Expires On:
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MMMM d, yyyy"
                  minDate={new Date()}
                  showYearDropdown
                  dropdownMode="select"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: '5px 0',
                    width: '100%'
                  }}
                  style={{
                    width: '100%',
                    padding: '12px',
                    border: '2px solid #4a90e2',
                    borderRadius: '6px',
                    fontSize: '16px',
                    color: '#333',
                    backgroundColor: '#f8f9fa'
                  }}
                />
                <p style={{
                  marginTop: '10px',
                  fontSize: '14px',
                  color: '#666',
                  fontWeight: '500'
                }}>
                  Selected Date: {startDate.toDateString()}
                </p>
              </div>
            )} */}



            <div className="modal-actions">
              <Button variant="contained" color="primary" onClick={handleItemSubmit}>
                {editingItemId ? "Update" : "Add"}
              </Button>
              <Button variant="contained" onClick={handleCloseModal}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal>


      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} severity={snackbarSeverity} onClose={handleSnackbarClose}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default MenuItemList;
