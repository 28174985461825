import { useRef, useState } from "react";
import axios from "../../../../../../../api/axios";

const AddNewSpecialUser = ({ inputs }) => {
  const roles = [
    {
      role_id: 4,
      name: "Waiter"
    },
    {
      role_id: 3,
      name: "Cashier"
    },
    {
      role_id: 2,
      name: "Manager"
    },
    {
      role_id: 5,
      name: "Store Keeper"
    },
    {
      role_id: 6,
      name: "Elevated Manager"
    },

  ];

  const [isBottomVisible, setIsBottomVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formData, setFormData] = useState({});
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const handleChange = (e, id) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const submitRequest = async (e) => {
    e.preventDefault();
    if (formData[1] === '' || formData[2] === '' || formData[3] === '' || !formData[1] || !formData[2] || !formData[3] ||!selectedRole) {
      setErrMsg('Please fill all required fields in order to create new user')
      setTimeout(function () {
        setErrMsg("")
      }, 3000);
    } 
      const business_entity_id = sessionStorage.getItem("configBizEntityId")
      try {
        const result = await axios.post(
          'businessEntities/addNewBusinessUser',
          {
            "role": selectedRole,
            "userName": formData[1],
            "contactNo": formData[3],
            "Age": formData[3],
            "BusinessEntityId": business_entity_id,
            "userEmail": formData[2],
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          }
        )
        if (result.status === 200) {
          console.log("Added user");
          toggleBottomVisibility();

        }
      } catch (error) {
        console.log("Error");
      }
    
  }

  const toggleBottomVisibility = () => {
    setIsBottomVisible(!isBottomVisible);
  };

  return (
    <div className="new">
      <div className="newContainer">
        <div className="top" style={{ display: 'flex', justifyContent: 'space-between' }} onClick={toggleBottomVisibility}>
          <h6>Create A New User</h6>
          <div style={{ marginLeft: 'auto', cursor: 'pointer' }} className="dropdown-arrow">
            {isBottomVisible ? "Hide ▲" : "Show ▼"}
          </div>
        </div>

        {isBottomVisible && (
          <div className="bottom">
            <div className="right">
              <p
                ref={errRef}
                className={errMsg ? "errmsg-login" : "offscreen"}
                aria-live="assertive"
                style={{ textAlign: 'center' }}
              >
                {errMsg}
              </p>

              <form>
                <>
                  {inputs.map((input) => (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <input
                        type={input.type}
                        placeholder={input.placeholder}
                        value={formData[input.id] || ''}
                        onChange={(e) => handleChange(e, input.id)}
                      />
                    </div>
                  ))}
                  <div className="formInput">
                    <label>Select Role:</label>
                    <select className="dropdown" value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                      <option value={null}>Select a role</option>
                      {roles.map(role => (
                        <option key={role.role_id} value={role.role_id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                    <button onClick={submitRequest} style={{ float: 'right' }}>Save User</button>

                  </div>
                </>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewSpecialUser;
